.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.game {
  display: flex;
  flex-flow: column wrap;
  /* background: blue; */
}

.tree {
  margin: auto;
}

.treeImage {
  display: block;
  max-height: 40vh;
}

.apple {
  animation: spin-fall 1s linear;
  animation-fill-mode: forwards;
}

.appleSpin {
  animation: spin 1s linear;
  animation-fill-mode: forwards;
}

.appleFall {
  animation: fall 1s linear;
  animation-fill-mode: forwards;
}

.calculation {
  height: 50vh;
  background: lightgreen;
  justify-content: space-evenly;
}

.instructions {
  justify-content: space-evenly;
}

.calculation > .equationSide {
  display: inline-block;
  flex-grow: 2;
}

/* TODO: add responsive sizing for mobile */
.equationSide {
  width: 300px;
}

.equationSide > .equationPart {
  display: inline-block;
  flex-grow: 2;
}

.equationPart {
  margin: 0.1em 0.2em 0 0.2em;
  width: auto;
  font-size: 15vh;
}

.shake {
  animation: shake 0.1s linear;
  animation-direction: alternate-reverse;
}

.buttonFail {
  background: salmon;
}

.buttonSuccess {
  background: lightblue;
}

.checkButton {
  width: auto;
  font-size: 5vh;
  margin-top: 10px;
}


/* animations */

@keyframes shake {
  from {
    transform: translateX(5px);
  }
  to {
    transform: translateX(-5px);
  }
}

@keyframes fall {
  from {
    transform: translateY(-30vh);
  }
  to {
    transform: translateY(-4.5vh);
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

/*
 * TODO: add bounce to apple; match animation-delay to fall duration
 *   sequence:
 *     fall 1s
 *     bounce 0.5s (translateY negative)
 *     fall-short 0.5s (translateY positive same distance as bounce)
 *   lateral: during bounce, simultaneously play translateX animation in random x direction
 */
@keyframes bounce {
  from {
    transform: translateY(-4.5vh);
  }
  to {
    transform: translateY(-9vh);
  }
}

@keyframes fall-short {
  from {
    transform: translateY(-9vh);
  }
  to {
    transform: translateY(-4vh);
  }
}

/* react-transition-group more appropriate here; need to determine current x pos */
/*
@keyframes lateral {
  from {
    transform: translateX(current);
  }
  to {
    transform: translateX(target);
  }
}
*/

/* this is for mobile */
@media (max-width: 768px) {
  .equationPart {
    font-size: 5vh;
  }
  .equationSide {
    width: 100px;
  }
}
